import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

import '../../utils/normalize.css';
import '../../utils/css/screen.css';

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allSitePage(
      filter: { path: {regex : "\/admin/"} },
    ) {
      edges {
        node {
          path
          component
          pluginCreator {
            name
            pluginFilepath
          }
        }
      }
    }
  }
`;

const AdminIndex = ({ data }, location) => {
  const { allSitePage } = data;
  let counter = 0;

  return (
    <div className="admin">
      ADMIN Pages
      <ul>
        {allSitePage.edges.map(({ node }) => {
          counter += 1;
          return (
            <li
              key={node.path}
              count={counter}
            >
              <Link to={node.path}>{node.path}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AdminIndex location={props.location} data={data} {...props} />
    )}
  />
);
